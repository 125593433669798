@media only screen and (max-width: 768px) {
  .loginBanner {
    //background-color: #085575;
    background: url(../../resources/images/Sign-In.png) no-repeat;
    border-radius: 50px !important;
    background-size: cover;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background-position: 50% 90%;
  }
  .login {
    min-height: 40vh;
    min-width: 50vw;
    background-color: #effafbb3;
    border-radius: 50px;
    position: absolute;
    top: 50%;
  }
}

.loginBanner {
  //background-color: #085575;
  background: url(../../resources/images/Sign-In.png) no-repeat;
  background-size: cover;
  background-position: 50% 90%;
  border-radius: 50px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.login {
  min-height: 40vh;
  min-width: 45vw;
  background-color: #e8efefb3;
  border-radius: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
